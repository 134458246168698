export default function clickToggleClass(clickToggleSelector) {
  if(clickToggleSelector) {
    for (let item of clickToggleSelector) {
      item.addEventListener('click', (e) => {
        e.preventDefault();
        const whereClassList = JSON.parse(item.dataset.clickTrigger);
        const ToggleClassList = Object.keys(whereClassList);
        ToggleClassList.forEach((classNameToAdd) => {
          const whereSelector = document.querySelectorAll(`.${whereClassList[classNameToAdd]}`);
          for (let i of whereSelector) {
            i.classList.toggle(classNameToAdd);
          };
        });
      }, false);
    };
  };
};

// 使い方
// ========================================================================================================
/*
  data-click-trigger='{"追加するクラス名01":"01をどこに追加するか？","追加するクラス名02":"02をどこに追加するか？"}'

  <button data-click-trigger='{"open":"accordion-title","on":"accordion-icon","off":"accordion-icon"}'>

  -----------------------------------
  <div class="accordion-title">
  <span class="accordion-icon off">

  ↓ click で toggle 発動 ↑

  <div class="accordion-title open">
  <span class="accordion-icon on">
  -----------------------------------
  注意：classNameToAddが被る場合許容できない

  → to_open to_close の入れ替え は 可能
    なぜなら、WhereToAddは一緒（同じボタンに適用させたい）だが、classNameToAddは違う（to_open と to_close）から
    例：data-click-trigger='{"to_open":"more_button"},{"to_close":"more_button"}'
    デフォルトで to_open が指定されていれば、 to_open は消え、 to_close が、追加。(逆も然り)

  → １つのエレメントで open を、２箇所に付けたい時、同じクラス名（WhereToAdd）をセレクターにしたくない は 不可
    パターンとして想定できないので OK
*/
