export default function tab(tabSelector) {
  if(tabSelector) {
    for (let item of tabSelector) {
      // console.log(item.dataset);
      const heads = item.querySelectorAll('.js-tab-button');
      const bodys = item.querySelectorAll('.js-tab-panel');
      for (let head of heads) {
        head.addEventListener('click', (e) => {
          e.preventDefault();
          const url = head.getAttribute('href');
          const currentId = url.split('#')[1];
          // console.log(currentId);
          const panel = document.getElementById(currentId);
          for (let head of heads) {
            // head.classList.remove("-current");
            head.parentElement.classList.remove("-current");
          }
          for (let body of bodys) {
            body.classList.remove("-current");
          }
          // head.classList.add("-current");
          head.parentElement.classList.add("-current");
          panel.classList.add("-current");
        }, false);
      };
    };
  };
};

// 使い方
// ========================================================================================================
/*
  a.js-tab-button(href="a123").-current
  a.js-tab-button(href="b456")  ← click target

  .js-tab-panel(id="a123").-current
  .js-tab-panel(id="b456")


  -----------------------------------
  <a href="a123" class="js-tab-button -current">
  <a href="b456" class="js-tab-button">

  <div id="a123" class="js-tab-panel -current">
  <div id="b456" class="js-tab-panel">

  ↓ click で toggle 発動 ↑

  <a href="a123" class="js-tab-button">
  <a href="b456" class="js-tab-button -current">

  <div id="a123" class="js-tab-panel">
  <div id="b456" class="js-tab-panel -current">
  -----------------------------------
*/
