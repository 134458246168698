export default function accordion(accordionSelector) {
  if(accordionSelector) {
    for (let item of accordionSelector) {
      const head = item.querySelector('.js-accordion-header');
      const body = item.querySelector('.js-accordion-body');
      head.addEventListener('click', (e) => {
        e.preventDefault();
        // head.parentElement.classList.toggle("-close");
        // head.parentElement.classList.toggle("-open");
        head.classList.toggle("-close");
        head.classList.toggle("-open");
        body.classList.toggle("-close");
        body.classList.toggle("-open");
      }, false);
    };
  };
};

// 使い方
// ========================================================================================================
/*
  .js-accordion
    .js-accordion-header ← click trigger
    .js-accordion-body

  -----------------------------------
  <div class="js-accordion -close">
    <div class="js-accordion-header -close">
    <div class="js-accordion-body -close">

  ↓ click で toggle 発動 ↑

  <div class="js-accordion -open">
    <div class="js-accordion-header -open">
    <div class="js-accordion-body -open">
  -----------------------------------
*/
