import onLoadAddClass from '@src/js/modules/onload-addclass';
import clickToggleClass from '@src/js/modules/click-toggle-class';
import accordion from '@src/js/modules/accordion';
import tab from '@src/js/modules/tab';
import '@src/sass/app.sass';

const setFillHeight = () => {
  const hd = document.querySelector('header.header_global');
  const fd = document.querySelector('footer.footer_global');
  const sd = document.querySelector('.nav_side');
  const vh = window.innerHeight * 0.01;
  const hvh = hd ? hd.offsetHeight : 0;
  const fvh = fd ? fd.offsetHeight : 0;
  const svh = sd ? sd.offsetHeight : 0;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
  document.documentElement.style.setProperty('--hvh', `${hvh}px`);
  document.documentElement.style.setProperty('--fvh', `${fvh}px`);
  document.documentElement.style.setProperty('--svh', `${svh}px`);
};

// 初期化
window.addEventListener('load', setFillHeight);
// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);


// resize時の window幅
window.addEventListener('load', bpSet);
window.addEventListener('resize', bpSet);
function bpSet(){
  document.querySelector('head meta[name="viewport"]').remove();
  let viewport = document.createElement('meta');
  viewport.setAttribute('name', 'viewport');
  if(window.matchMedia('(orientation:portrait)').matches) {
    if(screen.width <= 560) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
    } else if(screen.width >= 561 && screen.width <= 1240) {
      viewport.setAttribute('content', 'width=1240');
    } else {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
    }
  } else if(window.matchMedia("(orientation:landscape)").matches){
    if(screen.height <= 560) {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
    } else if(screen.height >= 561 && screen.height <= 1240) {
      viewport.setAttribute('content', 'width=1240');
    } else {
      viewport.setAttribute('content', 'width=device-width, initial-scale=1.0');
    }
  }
  document.getElementsByTagName('head')[0].appendChild(viewport);
}

window.addEventListener("load", () => {

  const loadclassSelector = {
    //classname: selectors(all)
    "js-body": document.querySelectorAll('body'),
  };
  onLoadAddClass(loadclassSelector);
  if(document.querySelectorAll('.item_index,.nav_side .-innerpage').length) {
    const itemIndexPage = {
      "-sp_hide": document.querySelectorAll('.wrap_side280')
    };
    onLoadAddClass(itemIndexPage);
  }

  // click-toggle-class
  // ===============================================================
  const clickToggleSelector =  document.querySelectorAll('.js-click-toggle');
  clickToggleClass(clickToggleSelector);

  // accordion
  // ===============================================================
  const accordionSelector =  document.querySelectorAll('.js-accordion');
  accordion(accordionSelector);

  // tab
  // ===============================================================
  const tabSelector = document.querySelectorAll('.js-tab');
  tab(tabSelector);

}, false);
